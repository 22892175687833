import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiRunningShoe, GiHandBag } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | АвтоКлін - Якісне миття та догляд за автомобілем
			</title>
			<meta name={"description"} content={"Ласкаво просимо на сайт АвтоКлін! Пропонуємо професійні послуги з миття та догляду за авто. Чистота та свіжість вашого автомобіля гарантовані.\n"} />
			<meta property={"og:title"} content={"Головна | АвтоКлін - Якісне миття та догляд за автомобілем"} />
			<meta property={"og:description"} content={"Ласкаво просимо на сайт АвтоКлін! Пропонуємо професійні послуги з миття та догляду за авто. Чистота та свіжість вашого автомобіля гарантовані.\n"} />
			<link rel={"shortcut icon"} href={"https://futureplanbit.com/img/84937.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://futureplanbit.com/img/84937.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://futureplanbit.com/img/84937.png"} />
			<meta name={"msapplication-TileImage"} content={"https://futureplanbit.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section
			padding="150px 0 150px 0"
			background="linear-gradient(180deg,#073120 0%,transparent 100%) 0 0 no-repeat,--color-darkL1 url(https://futureplanbit.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
			inner-width="90%"
			lazy-load
			sm-padding="80px 0 80px 0"
			quarkly-title="Team-16"
		>
			<Override slot="SectionContent" transition="color 0.2s ease 0s" />
			<Text
				margin="0px 80px 0px 80px"
				text-align="center"
				font="normal 500 64px/1.2 --fontFamily-sansHeavy"
				color="--light"
				lg-margin="0px 80px 0px 80px"
				md-margin="0px 0 0px 0"
				sm-font="normal 500 42px/1.2 --fontFamily-sansHeavy"
			>
				АвтоКлін - Ваші авто в надійних руках
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://futureplanbit.com/img/2.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://futureplanbit.com/img/3.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://futureplanbit.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="500px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="0px 25px 50px 25px"
					background="linear-gradient(0deg,#233219 0%,transparent 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://futureplanbit.com/img/5.jpg) 50% 0% /cover repeat scroll padding-box"
					hover-filter="brightness(50%)"
					sm-height="420px"
					sm-padding="0px 25px 30px 25px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Вітаємо в АвтоКлін!
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="980px"
				>
					Ласкаво просимо на вебсайт автомийки "АвтоКлін". Ми пропонуємо якісні послуги з миття та догляду за вашим автомобілем. Наша команда професіоналів забезпечить чистоту та свіжість вашого авто, використовуючи лише найкращі засоби.
				</Text>
			</Box><Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Чому обирають нас?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="980px"
				>
					Ми пишаємося тим, що надаємо найвищий рівень обслуговування. Наші клієнти цінують нас за уважне ставлення до деталей, швидке обслуговування та доступні ціни. Звертаючись до нас, ви можете бути впевнені в якості та надійності.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="center">
					Професійна команда
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="center">
					Сучасне обладнання
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="center">
					Зручне розташування
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="center"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="center">
					Програма лояльності для постійних&nbsp;клієнтів
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Запис онлайн
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--lead"
					lg-text-align="center"
					sm-font="normal 500 18px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Для вашої зручності ми пропонуємо можливість запису на миття онлайн. Виберіть зручний для вас час, і ми зробимо все можливе, щоб ваше авто виглядало як нове.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Звʼяжіться з нами
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://futureplanbit.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});